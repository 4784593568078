export interface Draw {
  lucky: boolean;
  id: number;
  html: string;
}

const draws: Draw[] = [
  {
    lucky: true,
    id: 9,
    html: "<b>此签望仙扳桂之象，凡事必遇贵⼈。</b> 本签为望仙扳桂之象。凡事必能遇赀⼈之象者。虽属上平。惟宜多作福。求神庇佑之。⽅有化凶趋吉之时。到头。君汝谋望⼀般喜讯好。⾼⼈。贵⼈也。⽇将佳⾳报之⽽来。要之。商贾者有利。",
  },
  {
    lucky: true,
    id: 22,
    html: "<b>此签有”抛砖引⽟”之意。<b/> 提醒当事⼈，施⽐受有福。在⼈与⼈的交往中，应主动⽽真诚的关⼼他⼈、了解他⼈的需要，在⾃⼰的能⼒范围之内，尽⼒帮忙协助、不求回报。所谓”助⼈为快乐之本”，成就别⼈，也就是成就⾃⼰。",
  },
  {
    lucky: true,
    id: 34,
    html: "对于本身所拥有的事物，不须看得太重，应能懂得取舍，不要吝于付出。⽆须害怕失去，应知有舍才有得。精神上的快乐充盈，绝对⼤于物质上的享受。把所求的计划降低标准，⾃有⾼⼈送上⼭顶，指引通达的途径。 病安讼理，尽可施为。",
  },
  {
    lucky: true,
    id: 46,
    html: "须知”好”与”坏”很难论定，甚⾄没有⼀定的准则。但⼈都是⽤⾃⼰⼼⾥的⼀套标准来评断，⽽每个⼈⼜有不同的想法，所以看出来的事情就有了好、坏、对、错的分别。如果能少点主观、多点客观，好恶⼼就不会那么重，也⽐较不会太固执。",
  },
  {
    lucky: true,
    id: 52,
    html: "凡事不要替⼈强出头，做好⾃⼰的本份，修身养性。 想上⾼楼躲避灾难，四周却处处是荆棘阻碍;须知万物的安排是早已经注定好的，世事难料，谁能知此刻的得意下⼀秒即变成失意。 如醉饮酒，如何且守，直待时来，⽆灾⽆咎。",
  },
  {
    lucky: true,
    id: 61,
    html: "此签有”⾔之成理”之意。 提醒当事⼈，勿过于主观。有些⼈本身通晓事理，加上对⼈事物观察⼊微，因此能够掌握先机，猜测事情的发展也往往不出所料。须知本身有⼀定的⾃信是必要且可喜的条件，但却不能太过或绝对地主观。",
  },
  {
    lucky: true,
    id: 65,
    html: "<b>此签有”世代传承”之意。</b> 提醒当事⼈，保持良好的典范。⼀个⼈的思想⾏为，很容易在不⾃觉中受到别⼈的影响。因此为⼈⽗⺟师⻓者，在教导晚辈⼦⼥时，应以身作则。",
  },
  {
    lucky: true,
    id: 66,
    html: "<b>此签⽇暖⻛和之象，凡事⼤吉⼤利。</b> 本签者。⽇暖⻛和之象。凡事⼤吉⼤利者。君尔之⻔庭清吉。梦也祯祥。古⼈劝之。积善之家庆有余。家运⼤吉⼤利也。积善者。先由⼼正。不存邪。助⼈为乐。 修身。布施之谓。因之。讼理婚成蚕⼜熟。易⾔之。",
  },
  {
    lucky: true,
    id: 78,
    html: "凡事要謹慎。不可輕聽⼈⾔。不可理⼈閒事。 ⾃⼰惟以收善為⼼。有意外之慮。 受⼈冤屈。不可與⼈爭論⾧短。雖終必有救星。但已經吃了眼前虧了。此乃先難後易，有惊無險，可逢凶化吉。如果輕信⼈⾔，則容易惹禍上⾝。要防⼩⼈。",
  },
  {
    lucky: false,
    id: 11,
    html: "过于主观在⽆形中会渐渐导致个⼈的⾃满⾃傲且⽆法接受其它不同的意⻅或批评。当知事情没有绝对，每个⼈的想法也随时都会改变。因此切忌光凭个⼈的⻆度来果断做决定或判断喜恶。仍应保持谦恭谨慎的态度，凡事客观、包容",
  },
  {
    lucky: false,
    id: 20,
    html: "此签有”⾃⽢堕落”之意。 意味当事⼈，勿随波逐流。有些⼈为了取悦或取信对⽅，不惜昧着良⼼，⼀味巴结。这种矫饰的作为，容易引起他⼈的反感与不⻮，纵使爬上⾼处，地位也难以稳固。凡事应坚守道德、⽆愧于⼼，靠⾃⼰努⼒打拚。",
  },
  {
    lucky: false,
    id: 33,
    html: "此签⾏⾈浪淘之象，凡事守旧则吉。 ⾏⾈浪淘之象。亦即是险境者。要之。君尔应知⽬下宜守旧。愿遵守静则吉。妄为则凶也。杨雄之仕王莽之朝为⼤夫。何以能胜⼤任。如在急⽔滩头放⽔之险⾏。待时若问营谋。时⾄⽅可为。千万叵轻举妄动。",
  },
  {
    lucky: false,
    id: 42,
    html: "淘沙⻅⾦之象也。凡百事劳⼼即有成之命运。⽬下居于⽇衔⼭之境。且前⾯正遇丛⼭峻岭。艰苦历程中。苦中不得不仰头祈求上苍垂护佑。⼰身犹在太虚之间。唯劳⼼可成之命。",
  },
  {
    lucky: false,
    id: 50,
    html: "勉励当事⼈，凡事勿轻⾔放弃。有时⼈在遭逢困境的时候，想法不⾃觉会变得很负⾯，浑身提不起劲，甚⾄对任何事都感到绝望。所谓天⽆绝⼈之路，在遇到挫折困境的时候，不要太快就否定了⾃⼰，也不要因为⼀时的悲观⽽想不开。",
  },
  {
    lucky: false,
    id: 60,
    html: "五五念五，骑⻰跨⻁，事虽劳⼼，于中有补。此签破镜重圆之象，凡事成就则吉。 菱花镜破镜重圆。为破镜重圆之象。凡事成就吉者也。不是上上者。为中中之签。是先凶后吉之签。交易持公平。",
  },
];

export function chooseDraw() {
  return draws[Math.floor(Math.random() * draws.length)];
}
